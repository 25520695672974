import { Button, ThemeProvider, createTheme } from "@mui/material";
import { UserSubmission } from "../Types/UserSubmissionType";
import { blueGrey, grey } from "@mui/material/colors";
import GetModalInstance from "../Utilities/Utilities";

export default function DetailsModal(props: { row: UserSubmission , submission_type:string}) {
  const colorTheme = createTheme({
    palette: {
      primary: {
        main: blueGrey[300],
      },
      secondary: {
        main: grey[900],
      },
    },
  });

  return (
    <ThemeProvider theme={colorTheme}>
      <div className="modal fade" id="detailsModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.submission_type.toUpperCase()} DETAILS</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-6">
                  <p className="h5">Name</p>
                </div>
                <div className="col-6">
                  <p className="h5">From</p>
                </div>
                <div className="col-6"> {props.row.first_name}</div>
                <div className="col-6"> {props.row.location}</div>
              </div>
              <p className="h5 mt-3">Testimony</p>
              <div className="row modal-body-content">
                <div className="col-10">
                  <p>{props.row.submission}</p>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer">
              <Button className="m-1" color="secondary" variant="contained" onClick={() => (GetModalInstance("detailsModal","hide"))}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
