import * as bootstrap from "bootstrap";

export default function GetModalInstance(modalId: string, action:string) {
  const element = document.querySelector(`#${modalId}`);
  if (element) {
    const modalInstance = bootstrap.Modal.getOrCreateInstance(element);
    if(action==="show"){
      modalInstance.show();
    }
    else if(action==="hide"){
      modalInstance.hide();
    }
    return modalInstance;
  }
}
