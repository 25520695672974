import { Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Dispatch } from "react";
import { UserSubmission } from "../Types/UserSubmissionType";
import GetModalInstance from "../Utilities/Utilities";
import { PRAYER_REQUEST_TYPE, TESTIMONY_TYPE } from "./DataConstants";

export const getColumns = (
  setRow: Dispatch<React.SetStateAction<UserSubmission>>,
  submissionType: string,
) => {
  let submissionName;

  if (submissionType === PRAYER_REQUEST_TYPE) {
    submissionName = "Prayer Request";
  }
  else if (submissionType === TESTIMONY_TYPE) {
    submissionName = "Testimony";
  }
  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "Name",
      width: 150,
      editable: false,
    },
    {
      field: "location",
      headerName: "Location",
      width: 230,
      editable: false,
    },
    {
      field: "submission_date",
      headerName: "Date Submitted",
      width: 200,
      editable: false
    },
    { field: "submission", headerName: submissionName, width: 450 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (cellValues) => {
        const handleActionButton = () => {
          setRow(cellValues.row);
          GetModalInstance("detailsModal", "show");
        };
        return (
          <Button
            variant="contained"
            color="success"
            onClick={handleActionButton}
          >
            View
          </Button>
        );
      },
    },
  ];
  return columns;
};
