import { Button, ThemeProvider, createTheme } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import { purple, grey } from "@mui/material/colors";
import GetModalInstance from "../Utilities/Utilities";

export default function ApiResponseModal(props: { message: string, isLoading: boolean }) {

    const colorTheme = createTheme({
        palette: {
            primary: {
                main: purple['A200'],
            },
            secondary:{
                main: grey['900']
            }
        },
    });
    return <ThemeProvider theme={colorTheme}>
        <div className="modal" id="apiResponseModal" tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.isLoading ? "" : "Success"}</h5>
                    </div>
                    {!props.isLoading ? <div className="modal-body">
                        <p>{props.message}</p>
                    </div> : <LoadingSpinner />}

                    <div className="modal-footer">
                        <Button
                            className="text-white"
                            variant="contained"
                            color="secondary"
                            onClick={()=>(GetModalInstance("apiResponseModal","hide"))}
                        >
                            Close
                        </Button>     
                   </div>
                </div>
            </div>
        </div>
    </ThemeProvider>

}