import moment from "moment";
import { InitialPageRenderType, UserSubmission } from "../Types/UserSubmissionType";

export const INIT_TESTIMONY_PRAYER_DATA: UserSubmission = {
  id: 0,
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  location: "",
  submission: "",
  submission_type:"",
  submission_date:moment().format("YYYY/MM/DD HH:mm:ss"),
};

export const INITIAL_PAGE_RENDER_DATA: InitialPageRenderType = {
  first_name: true,
  last_name: true,
  phone: true,
  email: true,
  location: true,
  submission: true,
};

export const TESTIMONY_TYPE = "testimony";
export const PRAYER_REQUEST_TYPE = "prayer";

export const EMAIL_REGEX =
  /^(?:(?![\\@:–[´;—\]&,<(">)])\S)+@(?:(?![\\@:–[´;—\]&,<(">)])\S)+\.(?:(?![\\@:–[´;—\]&,<(">)])\S)+$/i;
export const  NUMERIC_REGEX_OR_EMPTY_STRING = /^(?:\d+|)$/

export const SUBMISSION_SUCCESS_MESSAGE="Form successfully submitted."