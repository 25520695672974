import React from 'react';
import './App.css';
import Home from './components/Home'; 
function App() {
  return (
    <div className='container'>
    <Home/>
    </div>
  );
}

export default App;
