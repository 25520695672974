import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import {
  INIT_TESTIMONY_PRAYER_DATA,
  PRAYER_REQUEST_TYPE,
  TESTIMONY_TYPE,
} from "../Constants/DataConstants";
import "./Home.css";
import Form from "./Form";
import TestimonyPrayerTable from "./TestimonyPrayerTable";
import { TypeofSubmissionType, UserSubmission } from "../Types/UserSubmissionType";
import { getSubmission } from "../ApiServices/SubmissionApis";

export default function Home() {
  const [tabValue, setTabValue] = useState("1");
  const [prayerFormData, setPrayerFormData] = useState<UserSubmission>(
    INIT_TESTIMONY_PRAYER_DATA
  );
  const [testimonyFormData, setTestimonyFormData] = useState<UserSubmission>(
    INIT_TESTIMONY_PRAYER_DATA
  );
  //state to hold list of rows from the backend.
  const [listOfTestimonyRows, setListOfTestimonyRows] = useState<UserSubmission[]>([]);
  const [listOfPrayerRows, setListOfPrayerRows] = useState<UserSubmission[]>([]);

  //handleChange for changing the tab number. 
  const handleChange = (event: React.SyntheticEvent, value: string) => {
    setTabValue(value);
  };

  //Make Api calls to getSubmissions. 
  useEffect(() => {
    const testimonyType: TypeofSubmissionType = { submission_type: TESTIMONY_TYPE };
    const prayerType: TypeofSubmissionType = { submission_type: PRAYER_REQUEST_TYPE };
    try {
      //get testimonies API call.
      getSubmission(testimonyType).then(function (response) {
        if (response.data.data) {
          setListOfTestimonyRows(response.data.data);
        } else console.error(response);
      });
    } catch (error) {
      console.error(error);
    }
    //get prayer requests API call.
    try {
      getSubmission(prayerType).then(function (response) {
        if (response.data.data) {
          setListOfPrayerRows(response.data.data);
        } else console.error(response);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="card d-block p-4 header mb-4 shadow-sm rounded">
          <div className="fs-1 d-flex justify-content-center">
            Holy Hill Chapel Tennessee
          </div>
          <div className="fs-3 d-flex justify-content-center">
            Testimony & Prayer Portal
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card shadow bg-white rounded fixed-height">
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                centered
              >
                <Tab label="Share Testimony" value="1" />
                <Tab label="View Testimony" value="2" />
                <Tab label="Request Prayer" value="3" />
                <Tab label="View Prayer Request" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Form
                requestType={TESTIMONY_TYPE}
                formData={testimonyFormData}
                setFormData={setTestimonyFormData}
              />
            </TabPanel>
            <TabPanel value="2">
              <TestimonyPrayerTable submissionType={TESTIMONY_TYPE} submissionRows={listOfTestimonyRows} />
            </TabPanel>
            <TabPanel value="3">
              <Form
                requestType={PRAYER_REQUEST_TYPE}
                formData={prayerFormData}
                setFormData={setPrayerFormData}
              />
            </TabPanel>
            <TabPanel value="4">
              <TestimonyPrayerTable submissionType={PRAYER_REQUEST_TYPE} submissionRows={listOfPrayerRows} />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}
