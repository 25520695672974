import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DetailsModal from "./DetailsModal";
import { getColumns } from "../Constants/TableConstants";
import { TypeofSubmissionType, UserSubmission } from "../Types/UserSubmissionType";
import { useEffect, useState } from "react";
import { INIT_TESTIMONY_PRAYER_DATA } from "../Constants/DataConstants";
import { getSubmission } from "../ApiServices/SubmissionApis";

export default function TestimonyPrayerTable(props: { submissionType: string, submissionRows:UserSubmission[] }) {
  //state to hold row data to be passed to modal.
  const [rows, setRow] = useState<UserSubmission>(INIT_TESTIMONY_PRAYER_DATA);
 

  return (
    <>
      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={props.submissionRows}
          getRowId={row=>row.submission_date}
          columns={getColumns(setRow, props.submissionType)}
          autoPageSize
          checkboxSelection={false}
          disableColumnFilter={false}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>

      <DetailsModal row={rows} submission_type={props.submissionType} />
    </>
  );
}
