import axios from "axios";
import { INSERT_SUBMISSION, GET_SUBMISSION } from "../Constants/UrlConstants";
import { TypeofSubmissionType, UserSubmission } from "../Types/UserSubmissionType";

export async function insertSubmission(formData:UserSubmission){
    return await axios.post(INSERT_SUBMISSION, formData);
}

export async function getSubmission(submissionType:TypeofSubmissionType){
    return await axios.post(GET_SUBMISSION, submissionType)
}

